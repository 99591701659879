import * as React from "react";
import "./App.scss";

const App = () => {
  React.useEffect(() => {
    setTimeout(() => {
      window.location.replace(
        "https://www.abc.net.au/news/webstories/grammy-award-2022-red-carpet-fashion-90414"
      );
    }, 1000);
  });
  return <div></div>;
};

export default App;
